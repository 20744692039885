@import 'src/styles/mediaQueries.scss';
@import 'src/styles/colors.scss';

.wrapper {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    label {
        margin-left: 2px;
        margin-bottom: 0.75rem;
        color: color($var-color-app-dark-blue);
        font-weight: 700;

        .required {
            margin-left: 2px;
        }
    }

    .fields {
        display: flex;
    }

    &.half {
        width: 100%;

        @include lg {
           width: 50%;

           &:not(:first-of-type) {
            margin-left: 1rem;
           }
        }


    }
}