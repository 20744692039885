@import "src/styles/mediaQueries.scss";

.wrapper {
    width: 100%;
    margin: 35px auto;
    box-shadow: 0 4px 4px -1px rgb(0 0 0 / 10%);
    background: #fff;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    min-height: 20rem;
    padding: 0 3rem;
}