@import "src/styles/mediaQueries.scss";

.wrapper {
    margin: 4rem auto;
    @include lg {
        margin: 6rem auto;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &>div {
        display: flex;

        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    p {
        margin: 0.25rem;
        font-size: 1.1rem;
        text-align: center;
    }

    h1 {
        font-size: 3rem;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    img {
        width: 100%;
        max-width: 10rem;
        @include lg {
            max-width: 15rem;
        }
        margin: 24px 0 0;
    }

    button {
        margin: 2rem
    }
}