@import "src/styles/colors";

.wrapper {
    width: 100%;

    input {
        font-size: 0.875rem;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        display: block;
        min-width: 100%;
        font-family: inherit;
        height: 2.5rem;
        padding: 0 1rem;
        border-color: color($var-color-app-grey-1);
        outline: none;
        width: 100%;


        &:focus {
            border-color: color($var-color-app-grey-2);
            color: color($var-color-app-black)
        }

        &::placeholder {
            font-size: 0.875rem;
            color: color($var-color-app-grey-2)
        }
    }

    &.error {
        input {
            border-color: color($var-color-app-orange);
        }

        .error-field {
            margin-top: 4px;
            font-size: 0.75rem;
            color: color($var-color-app-orange);
        }
    }

    label {
        min-height: 13px;
        font-size: .75em;
        margin-top: 11px;
        margin-left: 2px;
        display: block;
        word-break: break-word;
    }
}