 @import "src/styles/colors.scss";

 .wrapper {
     width: 100%;
     height: 100%;
     position: relative;


     textarea {
         max-width: 100%;
         width: 100%;
         font-size: 0.875rem;
         height: 100%;
         border-radius: 8px;
         border-width: 1px;
         border-style: solid;
         display: block;
         border-color: color($var-color-app-grey-1);
         color: color($var-color-app-black);
         padding: 1rem;
         min-height: 2.5em;


         &::placeholder {
             color: color($var-color-app-grey-2);
             font-size: 0.875rem;

         }


         &::-webkit-resizer {
             // background-color: red;
         }

         &:focus,
         &:active {
             border-color: color($var-color-app-grey-2);
             color: color($var-color-app-black);
             outline: none;
         }
     }

     &.error {
         textarea {
             border-color: color($var-color-app-orange);
         }

         .error-field {
             margin-top: 4px;
             font-size: 0.75rem;
             color: color($var-color-app-orange);
         }
     }

 }