@import "src/styles/colors";
@import "src/styles/mediaQueries";

.wrapper {
  width: 100%;

  .drop-file-input {
    width: 100%;
    height: 8rem;

    @include lg {
      height: 13.5rem
    }

    overflow: hidden;

    border-radius: 8px;

    .base {
      $stroke-width: 1px;
      $dash-interval: 10px;
      $container-size: calc(100% - #{$stroke-width * 2});

      height: 100%;

      outline: $dash-interval dashed color($var-color-app-blue-light);
      outline-offset: -$dash-interval;

      transition: outline-color 0.1s ease-in-out;

      .input-container {
        position: relative;

        top: $stroke-width;
        left: $stroke-width;

        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;

        width: $container-size;
        height: $container-size;

        padding: 5px - $stroke-width;


        @include lg {
          justify-content: space-between;
          padding: 40px - $stroke-width;
        }

        background-color: #fff;

        border-radius: 8px - $stroke-width;

        transition: background-color 0.1s ease-in-out;

        .title {
          display: none;

          @include lg {
            display: block;
            color: color($var-color-app-dark-blue);
            font-size: 14px;
          }
        }

        .or {
          display: none;

          @include lg {
            display: block;
            color: color($var-color-app-dark-blue);
          }
        }
      }

      &.is-drag-accept {
        outline-color: color($var-color-app-blue-main);

        .input-container {
          background-color: color($var-color-app-blue-grey-1);
        }
      }

      &.is-drag-reject .input-container {
        .error {
          color: color($var-color-app-dark-blue);
          font-size: 14px;
          margin-bottom: 0.5rem;

          &::before {
            content: "";
            display: block;
            background-image: url("data:image/svg+xml,%0A%3Csvg width=%2733%27 height=%2733%27 viewBox=%270 0 33 33%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg id=%27WarningCircle%27 clip-path=%27url%28%23clip0_412_3825%29%27%3E%3Cpath id=%27Vector%27 d=%27M16.5 28.5C23.1274 28.5 28.5 23.1274 28.5 16.5C28.5 9.87258 23.1274 4.5 16.5 4.5C9.87258 4.5 4.5 9.87258 4.5 16.5C4.5 23.1274 9.87258 28.5 16.5 28.5Z%27 stroke=%27%23DF715F%27 stroke-miterlimit=%2710%27/%3E%3Cpath id=%27Vector_2%27 d=%27M16.5 17.5V10.5%27 stroke=%27%23DF715F%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3Cpath id=%27Vector_3%27 d=%27M16.5 22C15.9477 22 15.5 21.5523 15.5 21C15.5 20.4477 15.9477 20 16.5 20C17.0523 20 17.5 20.4477 17.5 21C17.5 21.5523 17.0523 22 16.5 22Z%27 fill=%27%23DF715F%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_412_3825%27%3E%3Crect width=%2732%27 height=%2732%27 fill=%27white%27 transform=%27translate%280.5 0.5%29%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            width: 100%;
            height: 33px;
            background-repeat: no-repeat;
            background-position-x: center;
            margin-bottom: 0.5rem;
          }

          color: color($var-color-app-orange)
        }
      }
    }
  }

  .upload-list {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0;

    li {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      display: flex;
      font-weight: 500;
      width: 100%;

      &:first-child {
        margin-top: 16px;
      }

      .file-data {
        background-color: color($var-color-app-white);

        @include lg {
          background-color: color($var-color-app-bg);
        }

        padding: 1rem;
        border-radius: 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .file-data-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          color: color($var-color-app-dark-blue);
          font-size: 1rem;
        }

        img {
          width: 26px;
          height: 26px;
          margin-right: 5px;
        }

        .img-file {
          padding: 6px;
          background: rgb(255, 255, 255);
          display: block;
          object-position: center;
          object-fit: cover;
          border-radius: 4px;
          border: 1px solid;
          border-color: #b5c2db;
        }

        svg {
          position: absolute;
          right: 3px;
          width: 1rem;
          height: 1rem;
          cursor: pointer;
        }
      }

      .delete-button {
        background-color: #dce5f6;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3 3.775v.196l-.001 10.342c0 .972-.677 1.684-1.604 1.685-2.048.003-4.097.003-6.145 0-.927 0-1.602-.713-1.603-1.686V3.775h9.352zm-7.004 2.44v7.337h1.325c.208 0 .221 0 .222-.198V6.452c0-.043.013-.092-.004-.125-.02-.044-.064-.106-.099-.107-.477-.007-.954-.004-1.444-.004zm4.654 0H6.628c-.21 0-.224 0-.225.195v7.13H7.95V6.215zM0 2.797V.938h.215c.942 0 1.883.004 2.825-.006a.428.428 0 00.277-.131c.208-.217.395-.462.6-.682A.354.354 0 014.15.006c.983-.008 1.967-.008 2.95 0 .078 0 .172.05.231.113.206.22.392.465.6.682.071.074.184.13.278.131.936.01 1.872.006 2.807.006h.228v1.859H0z' fill='%2357647E'/%3E%3C/svg%3E");
        width: 32px;
        border-radius: 4px;
        background-size: 12px;
        background-position: 50%;
        margin-left: 10px;
        background-repeat: no-repeat;
        color: transparent;
        cursor: pointer;

        &:hover {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3 3.775v.196l-.001 10.342c0 .972-.677 1.684-1.604 1.685-2.048.003-4.097.003-6.145 0-.927 0-1.602-.713-1.603-1.686V3.775h9.352zm-7.004 2.44v7.337h1.325c.208 0 .221 0 .222-.198V6.452c0-.043.013-.092-.004-.125-.02-.044-.064-.106-.099-.107-.477-.007-.954-.004-1.444-.004zm4.654 0H6.628c-.21 0-.224 0-.225.195v7.13H7.95V6.215zM0 2.797V.938h.215c.942 0 1.883.004 2.825-.006a.428.428 0 00.277-.131c.208-.217.395-.462.6-.682A.354.354 0 014.15.006c.983-.008 1.967-.008 2.95 0 .078 0 .172.05.231.113.206.22.392.465.6.682.071.074.184.13.278.131.936.01 1.872.006 2.807.006h.228v1.859H0z' fill='%23F23A3C'/%3E%3C/svg%3E");
        }
      }
    }
  }
}