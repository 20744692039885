@import "src/styles/colors";

.ghost-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 1rem;
  cursor: pointer;
  text-align: center;

  color: color($var-color-app-blue-grey-2);
  font-weight: 700;

  font-size: 1rem;

  background-color: #fff;

  border: 1px solid color($var-color-app-grey-4);
  border-radius: 8px;
  &:hover {
    color: color($var-color-app-blue-main)
  }
}
