@import "src/styles/colors";

.wrapper {
    height: 2.5em;
    width: 100%;


    .error {
        margin-top: 4px;
        font-size: 0.75rem;
        color: color($var-color-app-orange);
    }
}