@import "src/styles/colors";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.2rem;
    width: auto;
    color: #2c3345;
    font-size: 1em;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid;
    cursor: pointer;
    font-weight: 700;
    font-family: inherit;
    outline: 0;
    padding: 0.75rem 2rem;

    &.primary {
        background-color: color($var-color-app-dark-blue);
        color: color($var-color-app-white);
        border:none;
        
        &:not(.not-active){
            &:not(.disabled) {
                box-shadow: 0px 14px 32px 0px rgba(69, 74, 84, 0.60);
                &:hover {
                    background-color: color($var-color-app-dark-blue-2);
                }

            }
        }

         
        // &:not(.disabled){
        //     box-shadow: 0px 14px 32px 0px rgba(69, 74, 84, 0.60);
        //     &:hover {
        //         background-color: color($var-color-app-dark-blue-2);
        //     }
        // }

    }

    &.not-active,
    &.disabled {
        border-color: transparent;
        color: color($var-color-app-grey-2);
        background-color: color($var-color-app-grey-3);
    }
}