@import "src/styles/mediaQueries.scss";

.header {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include lg {
        flex-direction: row;
    }

    .info {
        margin-left: 0;
        margin-top: 2rem;

        @include lg {
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    h1 {
        margin: 0;
        font-size: 1.5rem;
        margin-bottom: 0.75rem;

        @include lg {
            margin-bottom: 8px;
        }
    }

    .form-type {
        display: flex;
        align-items: center;

        &::before {
            display: 'block';
            content: '';
            width: 7px;
            height: 7px;
            background-color: #4EB0C2;
            border-radius: 50%;
            margin-right: 5px;
        }

        color: #4EB0C2;
        font-weight: 700;
    }
}