@import 'src/styles/mediaQueries.scss';
@import 'src/styles/colors.scss';

.wrapper {
    margin-top: 10px;
    background-color: transparent;
    max-width: 752px;
    margin: 0 auto;
    margin-bottom: 5.5rem;
    border-radius: 8px;

    @include lg {
        background-color: color($var-color-app-white);
    }

    .form {
        width: 100%;
        padding: 0;

        @include lg {
            padding: 2rem;
        }

        font-family: Arial,
        sans-serif;

        .body {
            display: flex;
            flex-wrap: wrap;

            .row {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include lg {
                    flex-direction: row;
                }
            }
        }

        .column {
            width: 100%;

            @include lg {
                width: calc(50% - 0.5rem);
            }

            &:not(:first-of-type) {
                margin-left: 1rem;
            }
        }

        .phoneColumn {
            display: flex;

            &>div {
                &:first-of-type {
                    flex: 1 1 30%;
                }

                &:not(:first-of-type) {
                    margin-left: 8px;
                    flex: 1 1 70%;
                }
            }
        }

        textarea {
            height: 10rem;
        }

        .actions {
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 100;
            background: color($var-color-app-white);
            justify-content: center;
            padding: 1rem;
            width: 100%;
            border-radius: 10px 0px 0px 10px;
            box-shadow: -2px 2px 36px 0px rgba(6, 31, 51, 0.12);
            height: 5rem;

            button {
                margin: 0 1rem;
                cursor: pointer;
            }
        }
    }
}